import * as React from 'react'

import {ConnectButton} from "@rainbow-me/rainbowkit";
import {useAccount, useSignMessage} from 'wagmi'

import {verifyMessage} from 'ethers/lib/utils'

import {ethers} from 'ethers';

const domain = window.location.host;
const origin = window.location.origin;
const provider = new ethers.providers.Web3Provider(window.ethereum);
const signer = provider.getSigner();

const BACKEND_ADDR = "https://signphp.cristian.ovh";

let nonce = null;
async function getNonce(address, statement) {
    const res = await fetch(`${BACKEND_ADDR}/getNonce.php`,
        {
            credentials: 'include',
        });
    nonce = await res.text()
    return nonce;
}

let personalInfo = null;
setTimeout(async () => {
    personalInfo = await getPersonalInformation();
}, 10000);

async function sendForVerification(msg, sig) {

    let formdata = new FormData();
    formdata.append('msg', msg);
    formdata.append('sig', sig);

    const res = await fetch(`${BACKEND_ADDR}/verify.php`,
    {
            method: "POST",
            credentials: 'include',
            body: formdata,
    });

    return await res.text()
}

async function getPersonalInformation() {
    const res = await fetch(`${BACKEND_ADDR}/personalInformation.php`,
    {
            credentials: 'include'
        });
    return await res.text();
}

export const YourComponent = () => {
    const { address, isConnected } = useAccount();
    const recoveredAddress = React.useRef();

    const { data, error, isLoading, signMessage } = useSignMessage({
        onSuccess(data, variables) {
            // Verify signature when sign message succeeds

            /*
            console.log(variables.message);
            console.log(data);
            */
            sendForVerification(variables.message, data);
            /*
            const address = verifyMessage(variables.message, data)
            recoveredAddress.current = address
            */
        },
    })

    if (isConnected) {
        getNonce();
    }

    return (
        <>
            <ConnectButton
                chainStatus="icon"
                showBalance={false}
                accountStatus="address"
            />

            { isConnected &&
                (
                    <button
                        className="btn btn-signin"
                        onClick={(event) => {
                            event.preventDefault()
                            let statement = 'Sign in with Ethereum to the app.';

                            const message = JSON.stringify({
                                domain,
                                address,
                                statement,
                                uri: origin,
                                version: '1',
                                chainId: '1',
                                nonce: nonce
                            });
                            signMessage({ message })
                        }}
                    >
                        Sign in!
                    </button>
                )
            }

            {data && (
                <div id="walletData">
                    <div>Recovered Address: {recoveredAddress.current}</div>
                    <div>Signature: {data}</div>
                </div>
            )}
        </>

    )

};
