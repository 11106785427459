import "@rainbow-me/rainbowkit/styles.css";
import {
    createAuthenticationAdapter,
    RainbowKitAuthenticationProvider,
    getDefaultWallets,
    RainbowKitProvider,
    darkTheme
} from "@rainbow-me/rainbowkit";
import {
    mainnet,
    configureChains,
    createClient,
    WagmiConfig
} from "wagmi";
import { SiweMessage } from 'siwe';
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import { YourComponent } from "./YourComponent";

const { chains, provider } = configureChains(
    [mainnet],
    [alchemyProvider({ apiKey: 'oN0FA5IELxeUc8Gzqcw6dpHTUCRxJUU3' }), publicProvider()]
);

const { connectors } = getDefaultWallets({
    appName: "My RainbowKit App",
    chains
});

const wagmiClient = createClient({
    autoConnect: true,
    connectors,
    provider
});



export default function App() {

    const AUTHENTICATION_STATUS = true;

    return (
        <WagmiConfig client={wagmiClient}>
            <RainbowKitProvider
                chains={chains}
                modalSize="compact"
                theme={darkTheme()}
            >
                <YourComponent />
            </RainbowKitProvider>
        </WagmiConfig>
    );
}
